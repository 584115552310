<template>
<div class="row tgju-widgets-row dashboard-widget-profile widget-border-fix widget-padding">
    <Post auth="show" username="everybody" sendbox="enabled" :subject="this.$route.query.subject" col="col-12"></Post>
</div>
</template>
<script>
// این ویو برای سیستم کامنت در وب سایت اصلی تی جی جی یو مورد استفاده قرار میگیرد
import Post from '@/components/TgjuPosts/Post.vue'

export default {
    name: 'TgjuPostView',
    components: {
        Post,
    },
    data: function () {
        return {}
    },
    mounted() {},
    methods: {},
}
</script>
